<template>
  <div>
    <div class="login-bg"></div>
    <div class="login-content">
      <form class="login-body" @submit.prevent="onSubmit">
        <p v-if="isIOS" class="register" @click="goRegister">Daftar</p>
        <img src="@/assets/img/login-icon.png" class="login-icon" />
        <p class="title">Login Password</p>
        <div class="input-block">
          <input
            v-model.trim="phone"
            type="tel"
            maxlength="13"
            autofocus
            placeholder="Masukkan nomor handphone dengan awalan 08"
          />
        </div>
        <div class="input-block">
          <input
            v-model.trim="password"
            maxlength="8"
            type="tel"
            class="password"
            placeholder="Masukkan 8 digit angka password"
          />
        </div>
        <div class="forget-password" @click="forgetPassword">
          Lupa Password？
        </div>
        <button type="button" class="login-btn" @click="onSubmit">
          Login Password
        </button>
        <button type="button" class="login-btn" @click="goMessageLogin">
          Login dengan SMS
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import request from '@/utils/request'
import { validatePhoneNumber, validatePassword } from '@/utils/validator'
import { isIOS } from '@/utils/detect'

export default {
  name: 'Login',
  data () {
    return {
      phone: '',
      password: '',
      isIOS
    }
  },
  methods: {
    forgetPassword () {
      const { query } = this.$route
      this.$router.push({
        path: '/forgetPassword',
        query
      })
    },
    validate () {
      if (!this.phone) {
        Toast('Masukkan nomor handphone dengan awalan 08')
        return false
      }
      if (!validatePhoneNumber(this.phone)) {
        Toast('Nomor handphone salah')
        return false
      }
      if (!this.password) {
        Toast('Masukkan 8 digit angka password')
        return false
      }
      if (!validatePassword(this.password)) {
        Toast('Password salah')
        return false
      }
      return true
    },
    onSubmit () {
      if (!this.validate()) return
      if (this.flag) return
      this.flag = true
      const postData = {
        lodrginType: 1,
        usdrerPhone: this.phone,
        usdrerPassword: this.password
      }
      request.post('usdrerLogin', postData)
        .then(res => {
          this.$store.commit('recordInfo', {
            type: 'userSKInfo',
            value: res
          })
          localStorage.setItem('userSKInfo', JSON.stringify(res))
          this.$router.push('/home1')
        })
        .finally(() => {
          this.flag = false
        })
    },
    goMessageLogin () {
      const { query } = this.$route
      this.$router.push({
        path: '/messageLogin',
        query
      })
    },
    goRegister () {
      const { query } = this.$route
      this.$router.push({
        path: '/register',
        query
      })
    }
  }
}
</script>

<style scoped lang="less">
.login-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  padding-top: 111.48%;
  background: url(../assets/img/login-bg.png) no-repeat;
  background-size: cover;
  z-index: -1;
}

.login-content {
  width: 717px;
  height: 922px;
  margin-top: 295px;
  margin-left: 16px;
  background: url(../assets/img/login-content-bg.png) no-repeat;
  background-size: cover;
}

.login-body {
  position: relative;
  width: 663px;
  height: 881px;
  margin-left: 28px;
  border-radius: 40px;
  background-color: #fff;
}

.register {
  position: absolute;
  top: 40px;
  right: 30px;
  line-height: 50px;
  font-size: 36px;
  color: #303030;
}

.login-icon {
  width: 124px;
  height: 124px;
  padding-top: 42px;
  margin-left: auto;
  margin-right: auto;
}

.title {
  line-height: 50px;
  margin-top: 24px;
  margin-bottom: 32px;
  font-size: 36px;
  color: #1663ff;
  text-align: center;
}

.input-block {
  margin-top: 22px;
  margin-left: 27px;
  margin-right: 27px;

  input {
    width: 100%;
    height: 100px;
    line-height: 100px;
    border: none;
    border-radius: 50px;
    padding-left: 30px;
    margin-top: 22px;
    box-sizing: border-box;
    font-size: 30px;
    color: #333;
    background-color: #eee;

    &::placeholder {
      font-size: 24px;
      color: #999;
    }
  }
}

.password {
  -webkit-text-security: disc;
}

.forget-password {
  float: right;
  margin-top: 33px;
  margin-bottom: 43px;
  margin-right: 26px;
  font-size: 26px;
  color: #303030;
}

.login-btn {
  clear: both;
  width: 601px;
  height: 100px;
  line-height: 100px;
  border-radius: 50px;
  margin-left: auto;
  margin-right: auto;
  font-size: 36px;

  &:first-of-type {
    color: #fff;
    background-color: #1663ff;
  }

  &:last-of-type {
    margin-top: 57px;
    color: #303030;
    background-color: transparent;
  }
}
</style>
